.user-card-photo {
    height: 35px;
    width: 35px !important;
    border-radius: 100% !important;
    position: relative;
    margin-right: 10px;
    top: 50%;
    transform: translateY(-25%);
}

.user-card-info {
    font-size: .6em;
}

.user-card {
    line-height: 10px
}

.user-card-a {
    color: black;
    text-decoration: none;
}

.user-card-link {
    font-size: 0.85em;
}

.user-card-link:hover {
    color: rgb(42, 94, 205);
}

.badge-trailblazer-bg-less {
    -webkit-text-stroke: 1px rgb(9, 8, 7);
    color: rgb(192, 45, 26);
    font-weight: bold;
    padding: 3px;
    border-radius: 5px;
    margin-left: 5px;
}

.rep-title{
    color: rgb(42, 94, 205);
    font-size: .6em;
    font-weight: 700;
    font-style: italic;
    margin-left: 5px;
}