#editor-nav {
    width: 100vw;
    min-height: 50px;
    margin-bottom: 0px;
    position: absolute;
    padding: 10px;
    padding-bottom: 0px;
    top: 0;
    left: 0;
}

#editor-nav > hr {
    margin-bottom: 0px;
}

.nav-title {
    color: rgb(54, 171, 255);
    font-weight: 800;
    margin-left: 10px;
}

.NewPost textarea {
    height: 50vh;
    width: 100%;
    display: block;
    border-radius: 10px ;
    border: 1px solid #DEE2E6;
    padding: 10px;
}

.publish-btn {
    background-color: white;
    border-radius: 3px;
    color: rgb(54, 171, 255);
    border: 1px solid rgb(54, 171, 255);
    font-weight: 700;
    position: absolute;
    right: 10px;
}

.publish-btn:hover {
    background-color: rgb(50, 158, 235);
    border: 1px solid rgb(50, 158, 235);
    color: white;
}

.editor-label {
    font-weight: 700;
    font-style: italic;
    font-size: 12px;
    padding-left: 5px;
    border-left: 1px solid rgb(54, 171, 255);
    display: block;
}

.new-post-title {
    border-bottom: 3px solid #929393;
    /* max-height: 50px; */
}

.solution-title-text{
    position: absolute;
    top: 55px;
    font-weight: 800;
    font-size: 10px;
    left: 25px;
}

.post-message {
    position: fixed;
    bottom: 10px;
    width: 65vw;
    left: 50%;
    transform: translateX(-50%);
}

.NewPost {
    margin-right: 60px;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
    /* display: flex; */
}

.NewPost input {
    border: none;
    font-size: 2.5em;
    font-weight: 800;
}

.NewPost input:focus {
    outline-color: transparent;
    outline-style: none;
}