#Problem {
    border: 1px solid rgb(54, 171, 255);
    border-radius: 15px;
    margin-top: 12px;
    height: 350px;
    overflow: hidden;
}

#problem-content{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.problem-likes{
    height: 100%;
    flex: 1;
    margin-right: 5px;
    width: 40px
}

#Problem a {
    color:inherit;
    text-decoration: none;
}

.author {
    font-size: 15px;
    color: rgb(150, 150, 150);
}

.title {
    font-weight: 600;
    font-size: 2.5em;
    line-height: 1.2em;
}

div.post-content {
    z-index: 1;
    position: relative;
}

div.post-content div.post-overlay {
    position: absolute;
    background-image: linear-gradient(200deg, rgba(255, 255, 255, 0.283), rgb(255, 255, 255));
    z-index: 2;
    width: 100%;
    height: 500px;
}

.card-holder {
    position: relative;
    z-index: 1;
    flex: 11;
}

i.liked {
    color: rgb(54, 171, 255) !important;
    text-shadow: 0px 0px 5px rgb(54, 171, 255, 0.6);
}

.badge-box {
    position: relative;
}

@media (min-width: 600px) {
    .badge-box{
        position: absolute;
        right: 10px;
        display: inline-block;
    }
  }

.post-industry-badge {
    border: 2px solid black;
    border-radius: 5px;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 0.65em;
    margin-left: 5px;
}

.post-solution-badge {
    border: 2px solid rgb(54, 171, 255);
    color: rgb(54, 171, 255);
    border-radius: 5px;
    margin-right: 5px;
    font-weight: 600;
    font-size: 0.65em;
    padding: 5px 10px;
}

.problem-ts {
    display: block;
    font-size: 10px;
    font-weight: 800;
    color:rgb(171, 171, 171);
}

@media (min-width: 1250px) {
    .post-industry-badge {
        padding: 5px 10px;
        font-size: 0.9em;
    }
    
    .post-solution-badge {
        font-size: 0.9em;
        padding: 5px 15px;
    }
  }

.problem-content-container {
    display: flex; /* or grid */
    flex-direction: column; /* if vertical stacking is desired */
    gap: 10px; /* spacing between elements */
    width: 100%; /* Ensure it fills its parent */
    overflow: hidden; /* Prevent elements from overflowing */
}

#Problem .title:hover {
    cursor: pointer;
    color: grey;
}

#button-box{
    display: inline-block;
    font-size: 1.8em;
    margin-top: 70px;
    font-weight: 700;
}

#button-box .likes{
    margin-left: 20%;
}

#button-box .fa-solid{
    color: white;
    -webkit-text-stroke: 1px rgb(54, 171, 255);
    font-size: 1.4em;
}
#button-box .fa-solid:hover{
    color: rgb(54, 171, 255);
    text-shadow: 0px 0px 5px rgb(54, 171, 255, 0.6);
    cursor: pointer;
}

#problem-footer{
    text-align: right;
}

#Problem #status{
    background: rgb(93,227,109);
    background: linear-gradient(90deg, rgba(93,227,109,1) 35%, rgba(86,226,120,1) 53%, rgba(0,212,255,1) 100%);
    color: black;
    float: right;
    border: 1px solid rgba(149, 149, 149, 0.21);
    font-size: 12px;
    font-weight: 700;
    padding: 10px;
    border-radius: 10px
}