* {
  box-sizing: border-box;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div.image-holder > .image-asset {
  border-radius: 0;
}

.image-information {
  font-size: 0.8em;
}

.create-post-btn {
  position: absolute;
  right: 5%;
  text-decoration: none;
  border: 1px solid rgb(54, 171, 255);
  color: rgb(54, 171, 255);
  padding: 5px;
  font-weight: 600;
  border-radius: 5px;
}

.create-post-btn:hover{
  color: white;
  background-color: rgb(54, 171, 255);
}

.image-caption {
  color: rgb(112, 112, 112);
}

.font-awesome {
  font-family: Helvetica, 'FontAwesome', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1250px) {
  .create-post-btn {
    right: 15%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
