.Register {
    padding: 30px;
    letter-spacing: 0.2px;
    margin: auto;
    width: 100%;
    max-width: 800px;
    color: rgb(57, 57, 57);
}

.topic-selection {
    padding: 5px;
    border: 2px solid rgb(54, 171, 255);
    border-radius: 5px;
    margin-right: 5px;
}

.topic-selection:hover {
    cursor: pointer;
    color: white;
    background-color:rgb(54, 171, 255);
}

.topic-selected:hover {
    cursor: pointer;
}

.topic-selected {
    padding: 5px;
    border: 2px solid rgb(54, 171, 255);
    border-radius: 5px;
    margin-right: 5px;
    color: white;
    background-color:rgb(54, 171, 255);
}

.topics-container {
    margin-bottom: 40px;
}

.Register .privacy-policy{
    font-size: 0.78em;
}

.register-box{
    width: 100%;
    margin-top: 30px;
    padding: 30px;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    -moz-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    border-radius: 10px;
}

.submit-button{
    border-radius: 20px;
    letter-spacing: 0.2px;
    background: rgb(54,171,255);
    background: linear-gradient(161deg, rgba(54,171,255,1) 0%, rgba(160,143,237,1) 51%, rgba(227,181,236,1) 100%);
    -webkit-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    -moz-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    border: none;
    font-size: 1em;
    font-weight: 600;
}

.submit-button:hover{
    background: linear-gradient(161deg, rgb(77, 179, 253) 0%, rgb(177, 163, 238) 51%, rgb(226, 189, 233) 100%);
}

.privacy-policy {
    text-align: center;
    width: 100%;
    display: inline-block;
}

.red-border {
    border: 1px solid rgb(239, 45, 45);
}

small.error-message {
    position: relative;
    top: -12px;
    color: rgb(239, 45, 45);
}