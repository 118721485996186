.Solution {
    display: flex;
    line-height: 1.1em;
    flex: 12;
    flex-basis: 5%;
    margin-bottom: 10px;
}

.solutions-wrapper {
    min-height: 230px;
}

.page-number {
    border: 1px solid rgb(54, 171, 255);
    border-radius: 5px;
    text-decoration: none;
    font-weight: 800;
    padding: 5px;
    margin: 5px;
    
}

.page-number:hover {
    cursor: pointer;
}
.solution-number {
    margin-right: 5px;
    font-weight: 800;
}

.Solutions {
    padding: 30px;
    border-left: 1px solid rgba(184, 184, 184, 0.584);
    height: 100%;
}

.solutions-header-title {
    font-size: 1.5em;
    font-weight: 800;
    color: rgb(54, 171, 255);
}

.solution-author {
    font-size: 0.7em;
    font-weight: 800;
    color: rgb(54, 171, 255);
}

.solution-title {
    color: black;
    font-size: 1em;
    line-height: 5px;
    font-weight: 800;
    text-decoration: none;
}

.solution-title:hover{
    color: rgb(102, 102, 102)
}