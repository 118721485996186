/* Toolbar Container Styling */
.Toolbar {
    box-shadow: -11px 20px 37px 0px rgba(212, 212, 212, 0.71); /* Consolidated vendor prefixes */
    margin-bottom: 0;
    padding-bottom: 10px;
    position: relative;
    height: 220px;
}

.exit-btn {
    position: absolute;
    right: 2%;
    color: rgb(238, 62, 62);
}

.upload-title {
    font-weight: 800;
}

.exit-btn:hover {
    cursor: pointer;
}

.font-size {
    font-size: 12px;
    font-weight: 700;
    border: 2px solid rgb(54, 171, 255);
    border-radius: 5px;
}

.font-size-label {
    font-size: 12px;
    font-weight: 800;
    margin-right: 5px;
}

/* Lead Text Styling */
.lead-text {
    font-size: 12px;
    font-weight: 700;
    margin: 10px 0 0 0;
    text-decoration: underline;
}

/* Industry Selection Tag Styling */
.industry-selection {
    display: inline-block;
    border: 1px solid rgb(54, 171, 255);
    color: rgb(54, 171, 255);
    font-weight: 700;
    padding: 3px;
    font-size: 12px;
    margin: 3px;
    border-radius: 3px;
    cursor: pointer;
}

.industry-selected {
    color: white;
    background-color: rgb(54, 171, 255);
}

.industry-selection:hover {
    background-color: rgb(54, 171, 255);
    color: white;
}

/* Overlay Styling */
.Toolbar > .overlay {
    position: fixed;
    z-index: 2;
    background-color: rgba(151, 151, 151, 0.51);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

/* Image Modal Styling */
.toolbar-img-modal {
    position: fixed;
    z-index: 2;
    background-color: white;
    box-shadow: 1px 1px 10px black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 95vw;
    min-width: 90vw;
    min-height: 600px;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.2em;
    font-weight: 800;
}

div.snapshot-restore-btn {
    line-height: 12px;
    /* padding: 4px; */
    margin: 2px;
}

div.snapshot-restore-btn:hover {
    cursor: pointer;
    background-color: rgb(237, 237, 237);
}

div.snapshot-content-container {
    padding: 3px;
}

.snapshot-title {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 0px;
}

.snapshot-timestamp {
    font-size: 10px
}

#snapshot-container{
    display:none;
}

div.Toolbar > div.btn-container > button.snapshot-restore-btn:hover {
    color: rgb(46, 146, 217);
}


#clear-snapshot-btn {
    background-color: none;
    border: none;
    font-size: 10px;
    margin: 2px;
    border-radius: 5px;
    padding: 5px;
}

#clear-snapshot-btn:hover {
    background-color: none;
    border: none;
    font-size: 10px;
    margin: 2px;
    border-radius: 5px;
}


/* Image Placeholder Styling */
.image-placeholder {
    text-align: center;
    border: 3px dashed rgb(54, 171, 255);
    border-radius: 5px;
    height: 100%;
}

.img-preview-text {
    position: relative;
    top: 50%;
    color: rgb(54, 171, 255);
    transform: translateY(-50%);
    font-weight: 400;
}

/* Form Controls Inside Modal */
.toolbar-img-modal .row {
    min-height: 500px;
    line-height: 1em;
}

.toolbar-img-modal .col-md-5 input {
    border: 1px solid rgb(139, 139, 139);
    border-radius: 3px;
    font-weight: 400;
    height: 2em;
    padding: 5px !important;
    font-size: 15px;
    width: 100%;
}

.toolbar-img-modal .col-md-5 label {
    font-weight: 800;
    font-size: 15px;
}

/* Submit Button Styling */
.img-submit-btn {
    position: absolute;
    left: 10px;
    bottom: 20px;
    color: rgb(54, 171, 255);
    border: 2px solid rgb(54, 171, 255);
    font-weight: 600;
}

.link-modal {
    max-width: 700px !important;
    min-width: 350px !important;
    max-height: 300px !important;
    min-height: 300px !important;
}

.img-submit-btn:hover {
    color: white;
    background-color: rgb(54, 171, 255);
    border: 2px solid rgb(54, 171, 255);
}

.embed-text-area {
    max-height: 500px !important;
    min-height: 500px !important;
}

input.upload-form {
    width: 100%; /* Adjust the width as needed */
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #000; /* Text color */
    border: 1px solid rgb(139, 139, 139);
    border-radius: 5px; /* Rounded corners */
    outline: none; /* No outline on focus */
  }
  
  label.upload-label {
    display: block;
    font-size: 10px !important;
    font-weight: bold;
    letter-spacing: 1px;
    color: #888; /* Light gray color for the label */
    text-transform: uppercase; /* All caps */
  }

/* Toolbar Header Styling */
.toolbar-top {
    height: 15px;
    width: 100%;
    background-color: rgb(54, 171, 255);
}

/* Button Container Styling */
.Toolbar .btn-container button {
    border-radius: 0;
    padding: 8px;
    display: inline;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transition */
}

.Toolbar .btn-container button:hover {
    font-weight: 800;
    background-color: rgb(54, 171, 255);
    color: white;
}

.Toolbar .btn-container button.active {
    font-weight: 800;
    background-color: rgb(54, 171, 255);
    color: white;
}

@media (min-width: 1250px) {
    .Toolbar {
      height: 100%;
    }
    #snapshot-container{
        display: block;
    }
  }