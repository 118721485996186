/* Editor Container Styling */
.editor-container {
    padding: 10px;
    min-height: 500px;
    margin-top: 20px;
    /* border: 1px solid rgb(141, 141, 141); Specify solid border style */
    line-height: 1.5; /* Adjusted for readability */
}

/* Image Holder Styling */
.image-holder {
    /* display: inline-block; */
}

/* Image Asset Styling */
.image-asset {
    display: inline-block;
}

/* Placeholder Text Styling */
.placeholder-text {
    color: #999;
    font-style: italic;
}

/* Blinking Cursor Effect for Focused Editor */
.editor-container:focus-within .ProseMirror:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 1em;
    background-color: #333; /* Cursor color */
    margin-left: 2px;
    animation: blink 0.8s steps(1) infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}