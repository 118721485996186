#ListTrending {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@media (min-width: 1250px) {
    #ListTrending {
        min-width: 770px;
        width: 50%;
    }
  }

#ListTrending h1{
    font-weight: 600;
}

.popular-post-date {
    font-size: 0.6em;
}

.rating-title{
    text-decoration: none;
    color: black;
    font-size: 0.8em;
    font-weight: 600;
}

.trending-username {
    font-size: 0.7em;
    margin-left: 5px;
    font-weight: 600;
}

.user-trending-photo {
    width: 20px;
    height: 20px;
    display: inline-block;
}