.Register {
    padding: 30px;
    margin: auto;
    width: 30%;
}

.Login {
    max-width: 500px;
    width: 100%;
    margin: auto;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 50px;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    -moz-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
}

#login-holder {
    width: 100%;
    padding: 30px;
    height: 100% + 40px;
    /* background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(134,204,255,1) 50%, rgba(255,255,255,1) 100%); */
}

.sign-up-container {
    margin-top: 50px;
    font-size: 0.8em;
    padding: 30px;
}

.sign-up-btn {
    color: black;
    font-weight: 600;
    text-decoration: none;
}

.login-text{
    font-size: 2em;
    font-weight: 600;
    padding: 40px;
}

.login-box {
    padding: 30px;
}

.login-btn {
    border-radius: 30px;
    background: rgb(54,171,255);
    background: linear-gradient(161deg, rgba(54,171,255,1) 0%, rgba(160,143,237,1) 51%, rgba(227,181,236,1) 100%);
    border: none;
    font-weight: 600;
    font-size: 0.8em;
    display: inline-block;
    max-width: 500px;
    width: 100%;
}

#inputUsername {
    border:none;
    border-bottom: 1px solid rgb(187, 186, 186);
    border-radius: 0;
}

#inputPassword {
    border:none;
    border-bottom: 1px solid rgb(187, 186, 186);
    border-radius: 0;
}

.username-label {
    font-size: 0.8em;
}