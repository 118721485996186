#ProblemList{
    color: black;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.no-content-container {
    margin-top: 30px;
}

.centered-content-holder {
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#no-content-image {
    /* margin-top: 30px; */
}

@media (min-width: 1250px) {
    #ProblemList {
        min-width: 770px;
        width: 50%;
    }
  }

.popular-posts {
    border: 1px solid rgb(54, 171, 255);
    line-height: 1em;
    border-radius: 15px;
    margin-top: 12px;
    padding: 30px;
}

.sort-bar {
    border: 1px solid rgb(54, 171, 255);
    border-radius: 15px;
    padding: 12px 2px 0px 0px;
}

.sort-bar ul > li {
    display: inline-block;
    margin-left: 10px;
}

.sort-bar ul > li > a {
    text-decoration: none;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 8px;
    color: rgb(54, 171, 255);
}

.bar-item:hover {
    color: rgb(43, 134, 199);
}

#ProblemList .display-5 {
    font-weight: 600;
}

.active {
    text-decoration: underline;
    background-color: rgb(54, 171, 255);
    color: white !important;
}

.active:hover {
    background-color: rgb(40, 129, 193);
}


.popular-posts-container{
    position: relative;
    left: 62%;
    transform: translateX(-50%);
}

.spinner {
    font-size: 24px;
    height: 80px;
    width: 80px;
    position: relative;
    left: 50%;
    margin-top: 30px;
}

.user-headline {
    font-size: 12px;
    font-weight: 600;;
}

.rating-title {
    font-size: 15px;
}

.rating-text{
    position: relative;
    top: 20%;
    transform: translateY(-50%);
    font-size: 2em;
    font-weight: 800;
    color: rgb(206, 206, 206);
}

.user-headline-photo{
    border-radius: 50% !important;
    height: 30px !important;
    width: auto !important;
}