.Navbar{
    padding: 50px;
    text-align: center;
}

.Navbar .logo-holder{
    padding-bottom: 5px;
    position: relative;
}

.Navbar .row {
    margin-top: 20px;
}

.Navbar h1 {
    font-size: 3em;
    font-weight: 700;
    color:rgb(54, 171, 255);
}

.Navbar .search-bar {
    margin-top: 10px;
    border-radius: 20px;
    width: 80%;
    padding: 10px 20px;
    border: rgb(54, 171, 255) 1px solid;
}

.Navbar #search-bar {
    position: relative;
    max-width: 1000px;;
}

.search-btn-box {
    position: absolute;
    right: 30px;
    top: 18px;
    height: 30px;
    font-size: 1.4em;
    color: rgb(54, 171, 255);
    border-left: 1px solid rgb(54, 171, 255);
    padding-left: 20px;
    width: 40px;
}

.sign-in-btn {
    background-color: rgb(54, 171, 255);
    color: white !important;
    letter-spacing: .4px !important;
    font-weight: 600 !important;
    border-radius: 2px;
    font-size: 0.9em;
    padding: 5px 20px;
    position: relative;
}

.nav-user {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
}

.search-bar-drop-down {
    width: 100%;
    position: absolute;
    background-color: white;
    border: 1px solid rgb(54, 171, 255);
    border-top: none;
    outline: 1px solid rgba(54, 171, 255, 0.274);
    border-radius: 0px 0px 20px 20px;
    text-align: left;
    padding: 15px;
    z-index: 3;
    box-shadow: 0 0.25rem 0 0.25rem rgba(13,110,253,0.25);
}

.nav-link-wrapper{
    display: inline-block;
    font-weight: bold;
    color: rgb(98, 98, 98);
    max-width: 10%;
    min-width: 60px;
}

.nav-link-holder{
    padding-top: 20px;
    width: 95%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.nav-link-wrapper:hover{
    color: rgb(54, 171, 255);
    cursor: pointer;
    border-bottom: 2px solid rgb(54, 171, 255);
    text-decoration: none;
}

.nav-link-active{
    color: rgb(54, 171, 255);
    border-bottom: 2px solid rgb(54, 171, 255);
}

.nav-topic {
    position: absolute;
    top: 45px;
    font-size: 1.5em;
    left: 90%;
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.sign-in-btn:hover {
    background-color: rgb(50, 137, 199) !important;
}

.search-btn:hover{
    cursor: pointer;
}

.Navbar hr {
    color: rgb(198, 198, 198);
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
    height: 10px;
}

.Navbar .btn-outline-primary{
    color: rgb(54, 171, 255);
    border-color: rgb(54, 171, 255);
    border-radius: 10px;
    margin-left: 10px;
}

.Navbar .btn-outline-primary:hover{
    color: white;
    background-color: rgb(54, 171, 255);
    border-color: rgb(54, 171, 255);
}

.drop-on {
    border-bottom: none !important;
    border-radius: 20px 20px 0px 0px !important;
    box-shadow: 0 -0.1rem 0 0.25rem rgba(13,110,253,0.25) !important;
}

.Navbar input.checkbox {
    margin: 0;
    padding: 0;
    width: 20px;
    display: inline-block;
}

.Navbar #filters {
    width: 100%;
    display: inline-block;
    font-size: 12px;
}

.filter-column {
    margin-top: 7px !important;
    margin-bottom: 0px !important;
    width: 50%;
    display: inline-block;
}

/* General Dropdown Container */
.user-card-dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    min-width: 300px;
    background-color: #1b1b1b; /* Dark background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 5;
    padding: 10px;
    color: #fff; /* Light text */
    font-family: Arial, sans-serif;
}


/* Action Buttons */
.user-card-button {
    display: block;
    text-align: center;
    background-color: rgb(54, 171, 255);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.user-card-button:hover {
    background-color: rgb(32, 130, 200);
    cursor: pointer !important;
}

.user-card-mini-button {
    display: block;
    text-decoration: none;
    color: white;
    text-align: left;
    margin-left: 5px;
}

.user-card-mini-button:hover {
    color: rgb(168, 168, 168);
    cursor: pointer;
}

.user-card-toggle {
    position: absolute;
    z-index: 10;
    display: inline-block;
    font-size: 30px;
    text-align: right;
    right: 4%;
    transition: background-color 0.3s;
}

.user-drop-photo {
    height: 50px;
    filter: invert(100%) sepia(17%) saturate(224%) hue-rotate(225deg) brightness(117%) contrast(100%);
}

.user-card-toggle:hover {
    color: rgb(32, 130, 200);
}

.user-card-other-profiles {
    padding: 10px;
}

.user-card-other-profiles h4 {
    margin: 0 0 10px;
    font-size: 14px;
    color: #cfcfcf;
}

.profile-item {
    display: flex;
    align-items: center;
    padding: 5px 0;
    color: #cfcfcf;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-item:hover {
    background-color: #333;
}

.profile-item img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

@media (min-width: 1250px) {
    .filter-column {
        width: 25%
    }
  }