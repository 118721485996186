.Respond {
    padding: 30px;
    margin: 5px;
    margin-top: 10px;
    border: rgb(38, 174, 242) 1px solid;
    border-radius: 5px;
}

.Respond textarea{
    resize: none;
    max-height: 200px;
}

.Respond button{
    float: right;
    position: relative;

}

.Respond .btn-box{
    height: 25px;

}