.Post {
    padding: 20px auto 100px auto;
    max-width: 1200px;
    width: 95vw;
    margin: auto auto;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    -moz-box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    box-shadow: 0px 0px 10px -1px rgba(119, 119, 119, 0.505);
    border-radius: 20px;
}

.solutions-container {
    height: 100%;
}

.comments-wrapper{
    padding: 30px
}

.see-more-wrapper{
    padding: 30px;
}

.Post .post-title{
    font-size: 2.0em;
    font-weight: 800;
    margin-bottom: 0px;
    margin-top: -10px;
}

.Post .post-time{
    font-size: 10px;
    color:rgb(155, 155, 155);
    font-weight: 600;
}


div.lead-wrapper > p > span.post-solution-badge{
    font-size: .8em;
}

#solution-btn{
    position: absolute;
    right: 20px;
}
.carousel-link {
    color: white;
    text-decoration: none;
}

.carousel-link:hover {
    color: rgb(40, 140, 240);
}

.carousel-item img{
    display: inline-block;
    max-width: 100%;
    min-height: 250px;
}

div.active.carousel-item{
    background: none;
    /* background-color: #494949; */
}

div.see-more-content {
    display: inline-block;
    position: relative;
    z-index: -2;
    left: 50%;
    /* max-width: 75%; */
    transform: translateX(-50%);
}

div.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #4949499e;

}

.see-more-post {
    border: 1px solid black;
    padding: 3px;
    border-radius: 5px;
}

.Post div.content{
    padding: 30px;
    font-size: 1.0em;
}

.Post .content-text{
    font-size: 1.0em;
}

.Post .content-text img{
    max-width: 100%;
    display: inline-block;
    border-radius: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.next-content-carousel-item {
    text-align: center;
    background-color: none;
    height: 400px;
}

.carousel-img {
    display: inline-block;
    z-index: -2;
}

.carousel-caption {
    z-index: 2;
}

.Post div.lead-wrapper{
    position: relative;
    padding: 30px;
    border-radius: 25px;
    margin-bottom: 20px;
    min-height: 500px;
}