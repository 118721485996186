.User {
    width: 100%;
}

.User .main-user-container{
    width: 80%;
    margin-left: 10%;
}

div.user-details {
    min-height: 500px;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    display: inline-block;
}

div.row img {
    max-width: 100%;
    max-height: 400px;
    border-radius: 10px;
    /* border: 1px solid rgb(54, 171, 255); */
}

p.user-info {
    line-height: 23px;
    margin-top: 20px;
}

 .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
    /* position: absolute; */
  }

  g > text {
    font-size: 15px;
    font-weight: 600;
    font-family: "Roboto";
  }
  
  #radar-tooltip {
    position: absolute;
  }
  #radar-tooltip + .tooltip {
    pointer-events: none;
  }

.badge-trailblazer {
    border: 2px solid rgb(155, 85, 15);
    -webkit-text-stroke: 1px rgb(155, 85, 15);
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,35,9,1) 35%, rgba(0,212,255,0) 100%);
    color: rgb(224, 137, 38);
    font-weight: bold;
    padding: 3px;
    border-radius: 5px;
    margin-left: 5px;
}

.user-label{
    font-weight: 600;
    font-size: 24px;
}

.user-headline{
    font-size: 16px;
    font-weight: 400;
    color:rgb(111, 111, 111);
}

.user-location{
    font-size: 14px;
    color:rgb(158, 158, 158);

}

.left-user-rail {
    padding: 10px;
    height: 100vh;
    width: 32%;
    display: inline-block;
    max-width: 400px;
    margin-right: 3%;
    border-radius: 10px;

}

.user-company {
    text-decoration: none;
    margin-left: 5px;
    color: black;
    font-weight: 600;
}

.user-company:hover {
    text-decoration: underline;
    color: rgb(54, 171, 255);
    
}

.user-description {
    border: 1px solid rgb(190, 190, 190);
    padding: 30px;
    border-radius: 10px;
}

.user-experience {
    border: 1px solid rgb(190, 190, 190);
    padding: 30px;
    border-radius: 10px;
}

.stat-label{
    font-size: 16px;
    font-weight: bold;
}

.experience-card-img{
    height: 100px;
}

.right-user-rail {
    padding: 30px;
    height: 100vh;
    width: 65%;
    border-radius: 10px;
}

/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

 .react-calendar-heatmap {
    max-width: 1000px;
 }

 .react-calendar-heatmap text {
    font-size: 10px;
    fill: #aaa;
  }
  
  .react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 5px;
  }
  
  .react-calendar-heatmap rect:hover {
    stroke: #555;
    stroke-width: 1px;
  }
  
  /*
   * Default color scale
   */
  
  .react-calendar-heatmap .color-empty {
    fill: #eeeeee;
  }
  
  .react-calendar-heatmap .color-filled {
    fill: #86B6F6;
  }
  
  /*
   * Github color scale
   */
  
  .react-calendar-heatmap .color-github-0 {
    fill: #eeeeee;
  }
  .react-calendar-heatmap .color-github-1 {
    fill: #d7e5f8;
  }
  .react-calendar-heatmap .color-github-2 {
    fill: #B4D4FF;
  }
  .react-calendar-heatmap .color-github-3 {
    fill: #86B6F6;
  }
  .react-calendar-heatmap .color-github-4 {
    fill: #176B87;
  }